.storybook-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  text-align: center;
  border: 1px solid #777;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  background-color: #333;

  box-sizing: border-box;
  margin: 0;

}
.storybook-button--primary {
  color: #000;
  height: 148px;
  width:219px;
  font-weight: 700;
  background-color: #fff;
}
.storybook-button--primary div.labels{
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.storybook-button--primary div.labels div.icon{
  font-size: 40px;
  margin-right: 15px;
}
.storybook-button--primary div.labels div.icon path{
  color:"#000";
  stroke:#000;
}
.storybook-button--secondary {
  color: #000;
  height: 150px;
  width:219px;
  font-weight: 700;
  background-color: #fff;
}
.green{
  background-color: rgb(46, 126, 59);
  color:#fff;
}

.green div.labels div.icon path{
  color:"#fff";
  stroke:#fff;
}
.ricon{
  margin-top: 30px;
}
.storybook-button--secondary div.labels{
  display: flex;
  flex-direction: column;
}
.storybook-button--secondary div.labels div.icon{
  font-size: 70px;
  margin-bottom: 25px; 
}
.storybook-button--secondary div.labels div.icon path{
  color: rgba(46,126,59,1);

}

.storybook-button--small {
  font-size: 20px;
}
.storybook-button--medium {
  font-size: 25px;
}
.storybook-button--large {
  font-size: 30px;
}

