header {
  width: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
}
header div.header-wrap{
  width: 100%;
  margin: 17.36px 0 5px 84px;
  box-sizing: border-box;
}

header img {
  width: 253px;
  height: auto;
}