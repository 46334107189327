div.item{
    width: 320px;
    height: 280px;
    background-color: #ffffff;
    margin-bottom: 38px;
    margin-left: 82.5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

div.item div.thumbnail{
  width: 300px;
  height: 200px;
  padding: 10px 0 0 0;
}

div.item div.thumbnail img{
  width: 100%;
  height: 100%;
  height: auto;
}

div.item p {
  padding: 12px;
  line-height: 1.3;
  font-weight: bold;
}